import React, { createRef } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
} from "react-router-dom";
//import './App.css';
import {
  Menu,
  Segment,
  Sidebar,
  Icon,
  Sticky,
  Image,
  Ref,
  Tab,
  Label,
  Button,
  Loader
} from "semantic-ui-react";
import axios from "axios";
import Toilet from "./pages/toiletPage";
import EmployeePage from "./pages/employeePage";
import ActivityPage from "./pages/activityPage";
import StatusPage from "./pages/statusPage";
import Chart from "./pages/chart";
import Login from "./components/login";
//import Settings from './constans'
function App() {
  const contextRef = createRef();
  const history = useHistory();
  const [visible, setVisible] = React.useState(true);
  const [login, setLogin] = React.useState(false);
  const token = localStorage.getItem("jwtToken");
  const user = JSON.parse(localStorage.getItem("userData"));
  axios.defaults.headers.common = {
    Authorization: `Bearer ${token}`,
  };
  return (
      token ? (
        <Router>
          <Ref innerRef={contextRef}>
            <Sticky context={contextRef}>
              <Menu icon="labeled" size="large" style={{ marginLeft: "240px" }}>
                {/*<Menu.Item
                  name="sidebar"
                  onClick={(e, data) => setVisible(!visible)}
                >
                  <Icon name="sidebar" />
                </Menu.Item>*/}
                <Menu.Item style={{ padding: "0", left: "33%" }}>
                  <Image
                    src={require("./text-logo-locatr.png")}
                    style={{
                      width: "auto",
                      height: "auto",
                      margin: "0",
                      padding: "5px",
                    }}
                  />
                </Menu.Item>
                <Menu.Item position='right'>
                  <Label as='a' image >
                  <img src='/images/avatar/small/joe.jpg' />
                  {user.employeeName}
                  <Button primary onClick={ (e) => {
                    localStorage.removeItem('jwtToken');
                    localStorage.removeItem('userData');
                    //return (<Loader active inline='centered' />)
                    setTimeout(() => {
                      setLogin(!login);
                      //history.push("/login")
                    }, 1000);
                  }}>Çıkış</Button>
                </Label>
                
                </Menu.Item>
              </Menu>
            </Sticky>
          </Ref>
          <Ref innerRef={contextRef}>
            <Sidebar.Pushable
              as={Segment.Group}
              raised
              style={{ margin: "0", transform: "none" }}
            >
              <Sticky context={contextRef}>
                <Sidebar
                  as={Menu}
                  animation="scale down"
                  icon="labeled"
                  /*onHide={() => setVisible(false)} */
                  vertical
                  visible={visible}
                  style={{ backgroundColor: "#eaf1fb"}}
                >
                  <Menu.Item
                    style={{ display: "inline-block", padding: "20px" }}
                  >
                    <Image src={require("./logodeneme.png")} />
                  </Menu.Item>
                  <Link to="/">
                    <Menu.Item>
                      <Icon name="home" />
                      Ana Sayfa
                    </Menu.Item>
                  </Link>
                  <Link to="/areas">
                    <Menu.Item>
                      <Icon name="venus mars" />
                      Akıllı Tuvaletler
                    </Menu.Item>
                  </Link>
                  <Link to="/tasks">
                    <Menu.Item>
                      <Icon name="tasks" />
                      Aktif ve Tamamlanmış Görevler
                    </Menu.Item>
                  </Link>
                  <Link to="/employees">
                    <Menu.Item>
                      <Icon name="users" />
                      Çalışan Takip
                    </Menu.Item>
                  </Link>
                  <Link to="/profile">
                    <Menu.Item>
                      <Icon name="user circle" />
                      Profil
                    </Menu.Item>
                  </Link>
                  <Link to="/settings">
                    <Menu.Item>
                      <Icon name="configure" />
                      Ayarlar
                    </Menu.Item>
                  </Link>
                </Sidebar>
              </Sticky>
              <Sidebar.Pusher
                style={{
                  overflow: "scroll",
                  height: "100%",
                  marginLeft: "240px",
                }}
              >
                <Segment>
                  <Switch>
                    <Route exact path="/">
                      {/* Ana menu */}
                      <Chart />
                    </Route>
                    <Route exact path="/login">
                      <Login />
                    </Route>
                    <Route path="/tasks">
                      {/* Tüm Görevler */}
                      <ActivityPage />
                    </Route>
                    <Route path="/areas">
                      {/* Alan Sayfası */}
                      <Toilet />
                    </Route>
                    <Route path="/employees">
                      {/* Çalışan Sayfası */}
                      <EmployeePage />
                    </Route>
                    <Route
                      path="/area/:id"
                      render={(props) => <StatusPage {...props} />}
                    />
                    <Route
                      path="/changes/:id"
                      render={(props) => <ActivityPage {...props} />}
                    />
                    <Route
                      path="/employee/tasks/:id"
                      render={(props) => <ActivityPage {...props} />}
                    />
                    <Route path="/settings"> {/* Ayarlar Sayfası */}</Route>
                    {/* Spesifik Cihazın Görevleri */}

                    <Route path="/activity/:id">
                      {/* Spesifik Alanın Yapılan İşleri */}
                      <Toilet />
                    </Route>
                  </Switch>
                </Segment>
              </Sidebar.Pusher>
            </Sidebar.Pushable>
          </Ref>
        </Router>
      ) : (
        <Login />
        )

      
)}

export default App;
