import React, { useRef, useCallback, useState, useEffect } from "react";
import {
  Button,
  Card,
  Divider,
  Image,
  Placeholder,
  Grid,
  Icon,
} from "semantic-ui-react";

//import PrettySlider from "./PrettySlider";
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";

const firstOlympicsYear = 1896;
const lastOlympicsYear = 2016;
const timelineInterval = 2000; //ms

const sdk = new ChartsEmbedSDK({
  baseUrl: "https://charts.mongodb.com/charts-taurus-fxcsy", // Optional: ~REPLACE~ with the Base URL from your Embed Chart dialog
});

const barChart = sdk.createChart({
  chartId: "ff518bbb-923c-4c2c-91f5-4a2b3137f312",
   // Optional: ~REPLACE~ with the Chart ID from your Embed Chart dialog
});

const geoChart = sdk.createChart({
  chartId: "b1983061-ee44-40ad-9c45-4bb1d4e74884", // Optional: ~REPLACE~ with the Chart ID from your Embed Chart dialog
});
//
const iotChart = sdk.createChart({
    chartId: "54f68eeb-6460-4557-8f1e-81223643b0cd",
    height: '450px' // Optional: ~REPLACE~ with the Chart ID from your Embed Chart dialog
  });
  
const totalUsageChart = sdk.createChart({
    chartId: "ab942b02-12f3-4ef2-88e4-8a43e40fd7b0",
    height: '450px' // Optional: ~REPLACE~ with the Chart ID from your Embed Chart dialog
  });
const cabinUsageChart = sdk.createChart({
    chartId: "359bc319-1756-4e33-9118-84692e630f4d",
    height: '450px' // Optional: ~REPLACE~ with the Chart ID from your Embed Chart dialog
  });
  
const assetChart = sdk.createChart({
    chartId: "6cb34d40-8761-4944-bd30-66f5d69e9470",
    height: '450px' // Optional: ~REPLACE~ with the Chart ID from your Embed Chart dialog
  });

export default function Chart() {
  const refBarChart = useRef(null);
  const refGeoChart = useRef(null);
  const refIotChart = useRef(null);
  const refTotalChart = useRef(null);
  const refCabinChart = useRef(null);
  const refAssetChart = useRef(null);
  const [year, setYear] = useState(lastOlympicsYear);
  const [playing, setPlaying] = useState(false);
  const [loading, setLoading] = useState(false);

  const yearRef = React.useRef(year);
  yearRef.current = year;

  const timerIdRef = React.useRef();
  const replayRef = React.useRef(false);

  const renderBarChart = useCallback(async (ref) => {
    try {
      await barChart.render(ref);
    } catch (e) {
      console.error(e);
    }
  }, []);

  const renderGeoChart = useCallback(async (ref) => {
    try {
      await geoChart.render(ref);
    } catch (e) {
      console.error(e);
    }
  }, []);
  const renderIotChart = useCallback(async (ref) => {
    try {
      await iotChart.render(ref);
    } catch (e) {
      console.error(e);
    }
  }, []);

  const renderTotalChart = useCallback(async (ref) => {
    try {
      await totalUsageChart.render(ref);
    } catch (e) {
      console.error(e);
    }
  }, []);
  const renderCabinChart = useCallback(async (ref) => {
    try {
      await cabinUsageChart.render(ref);
    } catch (e) {
      console.error(e);
    }
  }, []);

  const renderAssetChart = useCallback(async (ref) => {
    try {
      await assetChart.render(ref);
    } catch (e) {
      console.error(e);
    }
  }, []);

  const setRefBarChart = useCallback(
    (ref) => {
      if (ref) {
        renderBarChart(ref);
      }
      // Save a reference to the node
      refBarChart.current = ref;
    },
    [renderBarChart]
  );

  const setRefGeoChart = useCallback(
    (ref) => {
      if (ref) {
        renderGeoChart(ref);
      }
      // Save a reference to the node
      refGeoChart.current = ref;
    },
    [renderGeoChart]
  );
  const setRefAssetChart = useCallback(
    (ref) => {
      if (ref) {
        renderAssetChart(ref);
      }
      // Save a reference to the node
      refAssetChart.current = ref;
    },
    [renderAssetChart]
  );

  const setRefIotChart = useCallback(
    (ref) => {
      if (ref) {
        renderIotChart(ref);
      }
      // Save a reference to the node
      refIotChart.current = ref;
    },
    [renderIotChart]
  );
  const setRefTotalChart = useCallback(
    (ref) => {
      if (ref) {
        renderTotalChart(ref);
      }
      // Save a reference to the node
      refTotalChart.current = ref;
    },
    [renderTotalChart]
  );

  const setRefCabinChart = useCallback(
    (ref) => {
      if (ref) {
        renderCabinChart(ref);
      }
      // Save a reference to the node
      refCabinChart.current = ref;
    },
    [renderCabinChart]
  );

  const handleChange = (_event, newValue) => {
    setYear(newValue);
  };

  const handleChangeCommitted = (_event, newValue) => {
    setYear(newValue);
    getDataFromAllPreviousYears(newValue);
  };

  // This function is creating the filter that will be executed on the data.
  // If you wish to run this example on your data, change it to accomodate your idea or make sure you have a "Year" field in your data source
  const getDataFromAllPreviousYears = (endYear) => {
    let filter = {
      $and: [
        { Year: { $gte: firstOlympicsYear } },
        { Year: { $lte: endYear } },
      ],
    };

    return Promise.all([
      geoChart.setFilter(filter),
      barChart.setFilter(filter),
    ]);
  };

  const play = () => {
    let currentYear = yearRef.current + 4;

    if (currentYear > lastOlympicsYear) {
      if (replayRef.current) {
        currentYear = firstOlympicsYear;
        replayRef.current = false;
      } else {
        clearInterval(timerIdRef.current);
        setPlaying(false);
        return;
      }
    }
    getDataFromAllPreviousYears(currentYear);
    setYear(currentYear);
  };

  const setTimelineInterval = () => {
    if (playing) {
      // we do this because the first play with setInterval is after the time specified
      // and the first filter should be instantaneous
      play();
      timerIdRef.current = setInterval(play, timelineInterval);
    } else {
      clearInterval(timerIdRef.current);
    }
  };

  const actionTimeline = () => {
    if (yearRef.current === lastOlympicsYear) {
      replayRef.current = true;
    }

    setPlaying(!playing);
  };

  useEffect(() => {
    setLoading(true);
     
    
        setTimeout(() => {
          setLoading(false);
        }, 1500)
   
  },[]);
  return (
    <Grid stackable>
      <Grid.Row columns={2}>
        <Grid.Column>
          <div id="iotChart" ref={setRefIotChart}></div>
        </Grid.Column>
        <Grid.Column>
          <div id="totalUsageChart" ref={setRefTotalChart}></div>
        </Grid.Column>
        <Grid.Column>
          <div id="cabinUsageChart" ref={setRefCabinChart}></div>
        </Grid.Column>
        <Grid.Column>
          <div id="assetChart" ref={setRefAssetChart}></div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
