import React, { useState } from "react";
import { Button, Form, Grid, Header, Image, Segment } from "semantic-ui-react";
import axios from "axios";
import { BrowserRouter as Router, Switch, Route, Link, useHistory } from "react-router-dom";
import { URL } from "../constans";
const LoginForm = () => {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const history = useHistory();
  const onChangeEmail = (e, { name, value }) => setEmail(value);
  const onChangePass = (e, { name, value }) => setPassword(value);
  const onSubmit = async (e) => {
    e.preventDefault();
    const obj = {
      email: email,
      password: password,
      roles: "Yönetici",
    };
    axios
      .post(URL + "/api/v1/employee/login", obj)
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem("jwtToken", res.data.token);
          localStorage.setItem('userData', JSON.stringify(res.data.user));
          //history.push("/");
        }

      })
      .catch((error) => {
        alert(error);
      });
  };
  return (
    <Grid textAlign="center" style={{ height: "100vh" }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as="h2" color="teal" textAlign="center">
          <Image src={require("../locatr.png")} /> Lütfen Sisteme Giriş Yapınız.
        </Header>
        <Form size="large" onSubmit={onSubmit}>
          <Segment stacked>
            <Form.Input
              email="name"
              value={email}
              onChange={onChangeEmail}
              fluid
              id="email"
              icon="user"
              iconPosition="left"
              placeholder="E-mail Adres"
            />
            <Form.Input
              fluid
              name="password"
              value={password}
              onChange={onChangePass}
              id="password"
              icon="lock"
              iconPosition="left"
              placeholder="Password"
              type="password"
            />

            <Button color="teal" fluid size="large">
              Giriş
            </Button>
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  );
};

export default LoginForm;
