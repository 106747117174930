import _ from "lodash";
import React, { useState, useEffect } from "react";
import { Table, Label, Step, Icon, Pagination, Image } from "semantic-ui-react";
//import faker from "faker/locale/tr";
//import SmartDataTable from "react-smart-data-table";
//import "react-smart-data-table/dist/react-smart-data-table.css";
import { URL, WS, mqttUser, mqttPass } from "../constans";
import axios from "axios";
const mqtt = require("mqtt");
//import socketIOClient from "socket.io-client";

function exampleReducer(state, action) {
  switch (action.type) {
    case "CHANGE_SORT":
      if (state.column === action.column) {
        return {
          ...state,
          data: state.data.reverse(),
          direction:
            state.direction === "ascending" ? "descending" : "ascending",
        };
      }

      return {
        column: action.column,
        data: _.sortBy(state.data, [action.column]),
        direction: "ascending",
      };
    default:
      throw new Error();
  }
}

const ActivityPage = (props) => {
  let filter = null;
  if(props.match !== undefined)
    filter = 1;
  //const testData = [];
  //const numResults = 100;
  //for (var i = 0; i < numResults; i++) {
  //  testData.push({
  //    _id: i,
  //    name: faker.name.findName(),
  //    "email.address": faker.internet.email(),
  //    phone_number: faker.phone.phoneNumber(),
  //    address: {
  //      city: faker.address.city(),
  //      state: faker.address.state(),
  //      country: faker.address.country(),
  //    },
  //  });
  //}
  const user = JSON.parse(localStorage.getItem('userData'));
  const [loading, setLoading] = useState(false);
  const [activities, setActivities] = useState([]);
  const [total, setTotal] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [state, dispatch] = React.useReducer(exampleReducer, {
    column: null,
    data: activities,
    direction: null,
  });
  let clientId;
  if(user){
  clientId = `Mqtt_Locatr_Web_${user.employeeName}_` + Math.random().toString(16).substr(2, 8);
  } else {
  clientId = `Mqtt_` + Math.random().toString(16).substr(2, 8);
}
  const options = {
    keepalive: 30,
    clientId: clientId,
    protocolId: 'MQTT',
    protocolVersion: 4,
    clean: true,
    reconnectPeriod: 1000,
    connectTimeout: 30 * 1000,
    //username: mqttUser,
    //password: mqttPass,
    //will: {
    //  topic: 'WillMsg',
    //  payload: 'Connection Closed abnormally..!',
    //  qos: 0,
    //  retain: false
    //},
    //rejectUnauthorized: false
  }
  useEffect(() => {
    setLoading(true);

    const client = mqtt.connect(`${WS}`,options);

    client.on("connect", function () {
      client.subscribe("outTopic", function (err) {});
      client.on("message", function (topic, message) {
        axios
          .get(`${URL}/api/v1/activity/?page=${activePage}&limit=${limit}`)
          .then((res) => {
            setActivities(res.data.data);
            setTotal(res.data.results);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    });
    axios
      .get(`${URL}/api/v1/activity/?page=${activePage}&limit=${limit}`)
      .then((res) => {
        setActivities(res.data.data);
        setTotal(res.data.results);
      })
      .catch((error) => {
        console.log(error);
      });

    setTimeout(() => {
      setLoading(false);
    }, 1500);
    return () => client.end();
  }, []);

  function SwitchCase(props) {
    switch (props.value) {
      case "open":
        return (
          <Table.Row negative>
            <Table.Cell>Henüz Görevli Atanmadı.</Table.Cell>
            <Table.Cell>
              {props.activity.iot.iotId}. {props.activity.iot.iotName}{" "}
              {props.activity.mission}
            </Table.Cell>
            <Table.Cell>
              {props.activity.area.floor}. Kat {props.activity.area.side}{" "}
              {props.activity.area.areaType} {props.activity.area.areaName}
            </Table.Cell>
            <Table.Cell>{props.activity.openTime}</Table.Cell>
            <Table.Cell>{props.activity.activeTime}</Table.Cell>
            <Table.Cell>{props.activity.closeTime}</Table.Cell>
            <Table.Cell>
              <Step.Group stackable="tablet">
                <Step active>
                  <Icon name="tasks" color="red" />
                </Step>
                <Step disabled>
                  <Icon name="male" />
                </Step>
                <Step disabled>
                  <Icon name="check" />
                </Step>
              </Step.Group>
            </Table.Cell>
          </Table.Row>
        );

      case "active":
        return (
          <Table.Row warning>
            <Table.Cell>
              <Label as="a" color="teal" image>
                <Image src="https://simg.nicepng.com/png/small/128-1280036_jpg-free-stock-female-vector-user-user-female.png" />
                {props.activity.worker.employeeName}
                <Label.Detail>{props.activity.worker.roles}</Label.Detail>
              </Label>
            </Table.Cell>
            <Table.Cell>
              {props.activity.iot.iotId}. {props.activity.iot.iotName}{" "}
              {props.activity.mission}
            </Table.Cell>
            <Table.Cell>
              {props.activity.area.floor}. Kat {props.activity.area.side}{" "}
              {props.activity.area.areaType} {props.activity.area.areaName}
            </Table.Cell>
            <Table.Cell>{props.activity.openTime}</Table.Cell>
            <Table.Cell>{props.activity.activeTime}</Table.Cell>
            <Table.Cell>{props.activity.closeTime}</Table.Cell>
            <Table.Cell>
              <Step.Group stackable="tablet">
                <Step disable>
                  <Icon name="tasks" color="green" />
                </Step>
                <Step active>
                  <Icon name="male" color="orange" />
                </Step>
                <Step disabled>
                  <Icon name="check" />
                </Step>
              </Step.Group>
            </Table.Cell>
          </Table.Row>
        );

      default:
        return (
          <Table.Row positive>
            <Table.Cell>
              <Label as="a" color="teal" image>
                <Image src="https://simg.nicepng.com/png/small/128-1280036_jpg-free-stock-female-vector-user-user-female.png" />
                {props.activity.worker.employeeName}
                <Label.Detail>{props.activity.worker.roles}</Label.Detail>
              </Label>
            </Table.Cell>
            <Table.Cell>
              {props.activity.iot.iotId}. {props.activity.iot.iotName}{" "}
              {props.activity.mission}
            </Table.Cell>
            <Table.Cell>
              {props.activity.area.floor}. Kat {props.activity.area.side}{" "}
              {props.activity.area.areaType} {props.activity.area.areaName}
            </Table.Cell>
            <Table.Cell>{props.activity.openTime}</Table.Cell>
            <Table.Cell>{props.activity.activeTime}</Table.Cell>
            <Table.Cell>{props.activity.closeTime}</Table.Cell>
            <Table.Cell>
              <Step.Group stackable="tablet">
                <Step disabled>
                  <Icon name="tasks" color="green" />
                </Step>
                <Step disabled>
                  <Icon name="male" color="green" />
                </Step>
                <Step disabled>
                  <Icon name="check" color="green" />
                </Step>
              </Step.Group>
            </Table.Cell>
          </Table.Row>
        );
    }
  }
  const { column, direction } = state;
  return (
    <Table sortable celled selectable style={{ textAlign: "center" }} stackable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            sorted={column === "name" ? direction : null}
            onClick={() => dispatch({ type: "CHANGE_SORT", column: "name" })}
          >
            Çalışan ve Görevi
          </Table.HeaderCell>
          <Table.HeaderCell>Görev Tanımı</Table.HeaderCell>
          <Table.HeaderCell>Konum</Table.HeaderCell>
          <Table.HeaderCell>Başlangıç Zamanı</Table.HeaderCell>
          <Table.HeaderCell>Görev Devir Zamanı</Table.HeaderCell>
          <Table.HeaderCell>Tamamlanma Zamanı</Table.HeaderCell>
          <Table.HeaderCell>Durum</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {_.filter(
          activities,
          filter !== null ? { area : props.match.params.id } : {}
        ).map((activity) => (
          <SwitchCase value={activity.status} activity={activity} />
        ))}
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan="7">
            <Pagination
              activePage={activePage}
              onPageChange={(e, { activePage }) => {
                setActivePage(activePage);
                axios
                  .get(
                    `${URL}/api/v1/activity/?page=${activePage}&limit=${limit}`
                  )
                  .then((res) => {
                    setActivities(res.data.data);
                    setTotal(res.data.results);
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }}
              totalPages={total}
            />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
};

export default ActivityPage;
