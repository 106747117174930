import _ from "lodash";
import React, { useEffect, useState } from "react";
import {
  Card,
  Progress,
  Grid,
  Image,
  Item,
  Statistic,
} from "semantic-ui-react";
import { URL, WS, mqttUser, mqttPass } from "../constans";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

const mqtt = require("mqtt");
const user = JSON.parse(localStorage.getItem('userData'));
let clientId;
if(user){
clientId = `Mqtt_Locatr_Web_${user.employeeName}_` + Math.random().toString(16).substr(2, 8);
} else {
clientId = `Mqtt_` + Math.random().toString(16).substr(2, 8);
}
const options = {
  keepalive: 30,
  clientId: clientId,
  protocolId: "MQTT",
  protocolVersion: 4,
  clean: true,
  reconnectPeriod: 1000,
  connectTimeout: 30 * 1000,
  //username: mqttUser,
  //password: mqttPass,
  //will: {
  //  topic: "WillMsg",
  //  payload: "Connection Closed abnormally..!",
  //  qos: 0,
  //  retain: false,
  //},
  //rejectUnauthorized: false,
};

function StatusPage(props) {
  const [loading, setLoading] = useState(false);
  const [devices, setDevices] = useState([]);
  let smell = null;
  let usage = null;
  let asset = null;
  let status = null;
  useEffect(() => {
    setLoading(true);
    const { id } = props.match.params;

    axios
      .get(`${URL}/api/v1/area/${id}`)
      .then((res) => {
        setDevices(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });

    setTimeout(() => {
      setLoading(false);
    }, 1500);

    const client = mqtt.connect(`${WS}`, options);
    client.on("connect", function () {
      //client.id(`webClient-${id}`);
      client.subscribe("Area/Tracking/Publish", function (err) {});
      client.on("message", function (topic, message) {
        // message is Buffer
        axios
          .get(`${URL}/api/v1/area/${id}`)
          .then((res) => {
            setDevices(res.data.data);
            if (res.status !== 304)
              toast.success("Success Notification !", {
                position: toast.POSITION.TOP_RIGHT,
              });
          })
          .catch((error) => {
            console.log(error);
          });
      });
    });
    return () => client.end();
  }, []);

  return (
    <>
      <ToastContainer />
      <Grid columns={8} divided stackable doubling>
        <Grid.Row>
          <Grid.Column>
            <Item.Image size="tiny" src={require("../images/heat.png")} />
            <Statistic size="tiny">
              <Statistic.Value>{devices.heat}</Statistic.Value>
              <Statistic.Label>Isı °C</Statistic.Label>
            </Statistic>
          </Grid.Column>
          <Grid.Column>
            <Item.Image size="tiny" src={require("../images/felt.jpeg")} />
            <Statistic size="tiny">
              <Statistic.Value>{devices.felt}</Statistic.Value>
              <Statistic.Label>Hissedilen °C</Statistic.Label>
            </Statistic>
          </Grid.Column>
          <Grid.Column>
            <Item.Image size="tiny" src={require("../images/humidity.png")} />
            <Statistic size="tiny">
              <Statistic.Value>{devices.humidity}</Statistic.Value>
              <Statistic.Label>Nem %</Statistic.Label>
            </Statistic>
          </Grid.Column>
          <Grid.Column>
            <Item.Image size="tiny" src={require("../images/bad.jpg")} />
            <Statistic size="tiny">
              <Statistic.Value>{devices.smell}</Statistic.Value>
              <Statistic.Label>Kötü Koku %</Statistic.Label>
            </Statistic>
          </Grid.Column>
          <Grid.Column>
            <Item.Image size="tiny" src={require("../images/flame.jpg")} />
            <Statistic size="tiny">
              <Statistic.Value>0</Statistic.Value>
              <Statistic.Label>Alev %</Statistic.Label>
            </Statistic>
          </Grid.Column>
          <Grid.Column>
            <Item.Image size="tiny" src={require("../images/dirty.png")} />
            <Statistic size="tiny">
              <Statistic.Value>20</Statistic.Value>
              <Statistic.Label>Kirlilik %</Statistic.Label>
            </Statistic>
          </Grid.Column>
          <Grid.Column>
            <Item.Image size="tiny" src={require("../images/usage.jpeg")} />
            <Statistic size="tiny">
              <Statistic.Value>{devices.usage}</Statistic.Value>
              <Statistic.Label>Kullanım</Statistic.Label>
            </Statistic>
          </Grid.Column>
          <Grid.Column>
            <Item.Image size="tiny" src={require("../images/women.jpeg")} />

            <Statistic size="tiny">
              <Statistic.Value>{devices.floor}.</Statistic.Value>
              <Statistic.Label>Kat</Statistic.Label>
            </Statistic>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid columns={4} divided stackable doubling>
        <Grid.Row>
          {_.filter(devices.cabin, { iotNumber: 1 }).map((device) => {
            switch (device.iotName) {
              default: {
                break;
              }
              case "Koku": {
                smell = device.status;
                break;
              }
              case "Hareket": {
                usage = device.usage;
                break;
              }
              case "Tuvalet Kağıdı": {
                asset = device.asset;
                status = device.status;
                break;
              }
            }
          })}
          <Grid.Column>
            <Card raised>
              <Image src={require("../images/cabin.png")} wrapped ui={false} />
              <Card.Content>
                <Grid columns={2}>
                  <Grid.Column>
                    <Image
                      src={require("../images/bad.jpg")}
                      wrapped
                      size="mini"
                    />
                    <Statistic label="Koku %" value={smell} size="mini" />
                  </Grid.Column>
                  <Grid.Column>
                    <Image
                      src={require("../images/usage.jpeg")}
                      wrapped
                      size="mini"
                    />
                    <Statistic label="Kullanım" value={usage} size="mini" />
                  </Grid.Column>
                </Grid>
              </Card.Content>
              <Card.Content extra>
                <Item.Content>
                  <Item.Meta style={{ textAlign: "center" }}>
                    Tuvalet Kağıdı
                  </Item.Meta>
                  <Item.Description>
                    <Progress percent={status} progress active indicating>
                      Kalan Sarf Malzemesi - Değişen Sarf {asset}
                    </Progress>
                  </Item.Description>
                </Item.Content>
              </Card.Content>
            </Card>
          </Grid.Column>
          {_.filter(devices.cabin, { iotNumber: 2 }).map((device) => {
            switch (device.iotName) {
              default: {
                break;
              }
              case "Koku": {
                smell = device.status;
                break;
              }
              case "Hareket": {
                usage = device.usage;
                break;
              }
              case "Tuvalet Kağıdı": {
                asset = device.asset;
                status = device.status;
                break;
              }
            }
          })}
          <Grid.Column>
            <Card raised>
              <Image src={require("../images/cabin.png")} wrapped ui={false} />
              <Card.Content>
                <Grid columns={2}>
                  <Grid.Column>
                    <Image
                      src={require("../images/bad.jpg")}
                      wrapped
                      size="mini"
                    />
                    <Statistic label="Koku %" value={smell} size="mini" />
                  </Grid.Column>
                  <Grid.Column>
                    <Image
                      src={require("../images/usage.jpeg")}
                      wrapped
                      size="mini"
                    />
                    <Statistic label="Kullanım" value={usage} size="mini" />
                  </Grid.Column>
                </Grid>
              </Card.Content>
              <Card.Content extra>
                <Item.Content>
                  <Item.Meta style={{ textAlign: "center" }}>
                    Tuvalet Kağıdı
                  </Item.Meta>
                  <Item.Description>
                    <Progress percent={status} progress active indicating>
                      Kalan Sarf Malzemesi - Değişen Sarf {asset}
                    </Progress>
                  </Item.Description>
                </Item.Content>
              </Card.Content>
            </Card>
          </Grid.Column>
          {_.filter(devices.cabin, { iotNumber: 3 }).map((device) => {
            switch (device.iotName) {
              default: {
                break;
              }
              case "Koku": {
                smell = device.status;
                break;
              }
              case "Hareket": {
                usage = device.usage;
                break;
              }
              case "Tuvalet Kağıdı": {
                asset = device.asset;
                status = device.status;
                break;
              }
            }
          })}
          <Grid.Column>
            <Card raised>
              <Image src={require("../images/cabin.png")} wrapped ui={false} />
              <Card.Content>
                <Grid columns={2}>
                  <Grid.Column>
                    <Image
                      src={require("../images/bad.jpg")}
                      wrapped
                      size="mini"
                    />
                    <Statistic label="Koku %" value={smell} size="mini" />
                  </Grid.Column>
                  <Grid.Column>
                    <Image
                      src={require("../images/usage.jpeg")}
                      wrapped
                      size="mini"
                    />
                    <Statistic label="Kullanım" value={usage} size="mini" />
                  </Grid.Column>
                </Grid>
              </Card.Content>
              <Card.Content extra>
                <Item.Content>
                  <Item.Meta style={{ textAlign: "center" }}>
                    Tuvalet Kağıdı
                  </Item.Meta>
                  <Item.Description>
                    <Progress percent={status} progress active indicating>
                      Kalan Sarf Malzemesi - Değişen Sarf {asset}
                    </Progress>
                  </Item.Description>
                </Item.Content>
              </Card.Content>
            </Card>
          </Grid.Column>
          {_.filter(devices.cabin, { iotNumber: 4 }).map((device) => {
            switch (device.iotName) {
              default: {
                break;
              }
              case "Koku": {
                smell = device.status;
                break;
              }
              case "Hareket": {
                usage = device.usage;
                break;
              }
              case "Tuvalet Kağıdı": {
                asset = device.asset;
                status = device.status;
                break;
              }
            }
          })}
          <Grid.Column>
            <Card raised>
              <Image src={require("../images/cabin.png")} wrapped ui={false} />
              <Card.Content>
                <Grid columns={2}>
                  <Grid.Column>
                    <Image
                      src={require("../images/bad.jpg")}
                      wrapped
                      size="mini"
                    />
                    <Statistic label="Koku %" value={smell} size="mini" />
                  </Grid.Column>
                  <Grid.Column>
                    <Image
                      src={require("../images/usage.jpeg")}
                      wrapped
                      size="mini"
                    />
                    <Statistic label="Kullanım" value={usage} size="mini" />
                  </Grid.Column>
                </Grid>
              </Card.Content>
              <Card.Content extra>
                <Item.Content>
                  <Item.Meta style={{ textAlign: "center" }}>
                    Tuvalet Kağıdı
                  </Item.Meta>
                  <Item.Description>
                    <Progress percent={status} progress active indicating>
                      Kalan Sarf Malzemesi - Değişen Sarf {asset}
                    </Progress>
                  </Item.Description>
                </Item.Content>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid columns={3} divided stackable doubling>
        <Grid.Row>
          {_.reject(devices.iot, { iotName: "Merkez" }).map((device) => (
            <Grid.Column>
              <Item>
                <Grid columns={3} divided>
                  <Grid.Row textAlign="center">
                    <Grid.Column>
                      <Item.Image
                        size="tiny"
                        src={require(`../images/${device.iotName}.png`)}
                      />
                    </Grid.Column>

                    <Grid.Column>
                      <Statistic
                        label="IOT Cihaz"
                        value={device.iotNumber + "."}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Statistic label="Değişen Sarf" value={device.asset} />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <Item.Content>
                  <Item.Meta style={{ textAlign: "center" }}>
                    {device.areaName}
                  </Item.Meta>
                  <Item.Description>
                    <Progress
                      percent={device.status}
                      progress
                      active
                      indicating
                    >
                      Kalan Sarf Malzemesi
                    </Progress>
                  </Item.Description>
                </Item.Content>
              </Item>
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
    </>
  );
}
export default StatusPage;
