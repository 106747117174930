//
//
//import _ from 'lodash'
//import React, { useEffect, useState } from "react";
//import {
//  Card,
//  Progress,
//  Grid,
//  Image,
//  Item,
//  Header,
//  Statistic,
//  Statistic,0.tcp.ngrok.io:18249
//  Button,
//} from "semantic-ui-react";
//import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
//
//function Settings (props) {
//    
//    const [DENEME, setLoading] = useState(5);
//    return (
//        <Button primary onClick={(e, data) => {
//            setLoading(10);
//            DENEME1 = DENEME;
//        }}>sd</Button>
//        
//        )
//    }
//    export default Settings;
    export const WS = 'wss://locatr.info/'
    export const URL = 'https://locatr.info'
    export const MQTT = '2.tcp.ngrok.io:10356'
    export const mqttUser = 'locatrCom'
    export const mqttPass = '{$Locatr?Devices/Pass}'
