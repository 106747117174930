import _ from "lodash";
import React, { Component } from "react";
import {
  Button,
  Card,
  Image,
  Placeholder,
  Select,
  Grid,
  Statistic,
} from "semantic-ui-react";
import { URL } from "../constans";
import axios from "axios";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import AreaActivity from './activityPage'

const floorOptions = [
  { key: "null", text: "Tüm Katlar", value: null },
  { key: "0", text: "Zemin Kat", value: 0 },
  { key: "1", text: "1. Kat", value: 1 },
  { key: "2", text: "2. Kat", value: 2 },
  { key: "3", text: "3. Kat", value: 3 },
  { key: "4", text: "4. Kat", value: 4 },
];
const AreaOptions = [
  { key: "null", text: "Tüm Alanlar", value: "" },
  { key: "Bay", text: "Bay", value: "Bay" },
  { key: "Bayan", text: "Bayan", value: "Bayan" },
  { key: "Engelli", text: "Engelli", value: "Engelli" },
  { key: "Mescit", text: "Mescit", value: "Mescit" },
  { key: "Çocuk", text: "Çocuk", value: "Çocuk" },
];

export default class ToiletPage extends Component {
  state = {
    loading: false,
    floor: null,
    area: "",
    areas: [],
  };

  componentDidMount() {
    this.setState({ loading: true });

    axios
      .get(URL + "/api/v1/area")
      .then((res) => {
        this.setState({
          areas: res.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    setTimeout(() => {
      this.setState({ loading: false });
    }, 1000);
  }

  render() {
    const { loading, areas, floor, area } = this.state;

    return (
      <>
        <Select
          placeholder="Listelemek Istediğiniz Alanı Seçiniz."
          options={floorOptions}
          value={floor}
          onChange={(e, data) => this.setState({ floor: data.value })}
        />
        <Select
          placeholder="Listelemek Istediğiniz Katı Seçiniz."
          options={AreaOptions}
          value={area}
          onChange={(e, data) => this.setState({ area: data.value })}
        />
        <Card.Group doubling itemsPerRow={6} stackable>
          {_.filter(
            areas,
            floor !== null && area !== ""
              ? { areaType: this.state.area, floor: this.state.floor }
              : {}
          ).map((area) => (
            <Card key={area._id} link>
              {loading ? (
                <Placeholder>
                  <Placeholder.Image square />
                </Placeholder>
              ) : (
                <Image
                  href={"/area/" + area._id}
                  src={
                    "https://thumbs.dreamstime.com/b/cartoon-toilet-hug-illustration-ready-to-give-47825091.jpg"
                  }
                />
              )}

              <Card.Content>
                {loading ? (
                  <Placeholder>
                    <Placeholder.Header>
                      <Placeholder.Line length="very short" />
                      <Placeholder.Line length="medium" />
                    </Placeholder.Header>
                    <Placeholder.Paragraph>
                      <Placeholder.Line length="short" />
                    </Placeholder.Paragraph>
                  </Placeholder>
                ) : (
                  <Grid columns={2} divided>
                    <Grid.Row>
                      <Grid.Column>
                        <Card.Header>{area.areaName}</Card.Header>
                        <Card.Meta>{area.areaType}</Card.Meta>
                        <Card.Description>{area.description}</Card.Description>
                      </Grid.Column>
                      <Grid.Column>
                        <Statistic>
                          <Statistic.Value>{area.floor}.</Statistic.Value>
                          <Statistic.Label>Kat - {area.side}</Statistic.Label>
                        </Statistic>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                )}
              </Card.Content>

              <Card.Content extra>
                <Link to={"/area/" + area._id}>
                  <Button primary disabled={loading}>
                    Detaylı Takip
                  </Button>
                </Link>
                <Link to={"/changes/" + area._id}>
                <Button color="teal" disabled={loading}>
                    Tamamlanan Görevler
                    
                  </Button>
                </Link>
                
              </Card.Content>
            </Card>
          ))}
        </Card.Group>
      </>
    );
  }
}
