import _ from "lodash";
import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Image,
  Placeholder,
  Grid,
  Icon,
} from "semantic-ui-react";
import { URL, WS, mqttUser, mqttPass } from "../constans";
import axios from "axios";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
const mqtt = require("mqtt");
//const HttpsProxyAgent = require('https-proxy-agent');
function EmployeePage() {
  const [loading, setLoading] = useState(false);
  const [employees, setEmployees] = useState([]);
  const user = JSON.parse(localStorage.getItem('userData'));
  //const [active, setActive] = useState(true);
  const mqtt = require("mqtt");
  let clientId;
  if(user){
  clientId = `Mqtt_Locatr_Web_${user.employeeName}_` + Math.random().toString(16).substr(2, 8);
  } else {
  clientId = `Mqtt_` + Math.random().toString(16).substr(2, 8);
  }
  const options = {
    keepalive: 30,
    clientId: clientId,
    protocolId: "MQTT",
    protocolVersion: 4,
    clean: true,
    reconnectPeriod: 1000,
    connectTimeout: 30 * 1000,
    //username: mqttUser,
    //password: mqttPass,
    //will: {
    //  topic: "WillMsg",
    //  payload: "Connection Closed abnormally..!",
    //  qos: 0,
    //  retain: false,
    //},
    //rejectUnauthorized: false,
  };
  useEffect(() => {
    setLoading(true);

    const client = mqtt.connect(`${WS}`, options);

    client.on("connect", function () {
      client.subscribe("outTopic", function (err) {});
      client.on("message", function (topic, message) {
        axios
          .get(URL + "/api/v1/employee")
          .then((res) => {
            setEmployees(res.data.data);
            //setActive((prevState) => !prevState);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    });
    axios
      .get(URL + "/api/v1/employee")
      .then((res) => {
        setEmployees(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });

    setTimeout(() => {
      setLoading(false);
    }, 1500);
    return () => client.end();
  }, []);

  return (
    <>
      <Card.Group
        doubling
        itemsPerRow={6}
        stackable
        style={{ textAlign: "center" }}
      >
        {_.map(employees, (employee) => (
          <Card key={employee._id} link>
            {loading ? (
              <Placeholder>
                <Placeholder.Image rectangular />
              </Placeholder>
            ) : (
              <Image
                href={"/employee/tasks/" + employee._id}
                src="https://fiverr-res.cloudinary.com/images/q_auto,f_auto/gigs2/112692698/original/31a5d2469689575beee06ffcf4e9e76abab3abe2/logo-design-for-profile-picture-dessin-pour-photo-de-profil.png"
              />
            )}

            <Card.Content>
              {loading ? (
                <Placeholder>
                  <Placeholder.Header>
                    <Placeholder.Line length="very short" />
                    <Placeholder.Line length="medium" />
                  </Placeholder.Header>
                  <Placeholder.Paragraph>
                    <Placeholder.Line length="short" />
                  </Placeholder.Paragraph>
                </Placeholder>
              ) : (
                <>
                  <Card.Header>{employee.employeeName}</Card.Header>
                  <Grid>
                    <Grid.Row columns={2} divided>
                      <Grid.Column>
                        <Card.Meta>
                          {employee.roles} {employee.employeeGender}
                        </Card.Meta>
                      </Grid.Column>
                      <Grid.Column>
                        {employee.active === false ? (
                          <Icon name="circle" color="grey" />
                        ) : (
                          <Card.Description>
                            {" "}
                            <Icon name="circle" color="green" />{" "}
                            {JSON.stringify(employee.coordinates[0])}
                          </Card.Description>
                        )}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </>
              )}
            </Card.Content>

            <Card.Content extra>
              <Link to={"/employee/" + employee._id}>
                <Button disabled={loading} primary>
                  Tamamlanan Görevler
                </Button>
              </Link>
              <Link to={"/location/" + employee._id}>
                <Button disabled={loading}>Son Konumu</Button>
              </Link>
            </Card.Content>
          </Card>
        ))}
      </Card.Group>
    </>
  );
}

export default EmployeePage;
